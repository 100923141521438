import { Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PageLoader from "components/pageLoader";
import NoMatchPage from "pages/404";
import Login from "./pages/authentication/login";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Index from "./pages";

const LazyAdmin = React.lazy(() => import("pages/admin/index"));
const LazyRegister = React.lazy(() => import("pages/authentication/register"));
const LazyRecords = React.lazy(() => import("pages/admin/records"));
const LazySettings = React.lazy(() => import("pages/admin/settings"));
const LazyAccount = React.lazy(() => import("pages/account/index"));
const LazyManageAdmin = React.lazy(() => import("pages/admin/manageAdmin"));
const LazyAdminSecurity = React.lazy(() => import("pages/admin/adminSecurity"));
const LazyManager = React.lazy(() => import("pages/manager/index"));
const LazyManagerHotels = React.lazy(() => import("pages/manager/hotels"));
const LazyManagerSecurity = React.lazy(() => import("pages/manager/security"));
const LazyBookSuccessful = React.lazy(() => import("pages/book-successful"));

const LazyHotelList = React.lazy(() =>
  import("pages/account/myHotels/hotelList")
);
const LazyHotelCatalog = React.lazy(() => import("pages/hotels/hotelCatalog"));
const LazySingleHotel = React.lazy(() =>
  import("pages/account/myHotels/singleHotel")
);
const LazyAHotel = React.lazy(() => import("pages/hotels/singleHotel"));
const LazyStaff = React.lazy(() => import("pages/staff/index"));
const LazyStaffHotel = React.lazy(() => import("pages/staff/staffHotel"));

function App() {
  // index.js or app.js

  if ("serviceWorker" in navigator) {
    navigator.serviceWorker
      .register("/service-worker.js")
      .then((registration) => {
        console.log(
          "Service Worker registered with scope:",
          registration.scope
        );
      })
      .catch((error) => {
        console.error("Service Worker registration failed:", error);
      });
  }

  return (
    <>
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/login" element={<Login />} />
        <Route
          path="/register"
          element={
            <React.Suspense fallback={<PageLoader />}>
              <LazyRegister />
            </React.Suspense>
          }
        />
        <Route
          path="/book-successful"
          element={
            <React.Suspense fallback={<PageLoader />}>
              <LazyBookSuccessful />
            </React.Suspense>
          }
        />
        <Route
          path="/hotels"
          element={
            <React.Suspense fallback={<PageLoader />}>
              <LazyHotelCatalog />
            </React.Suspense>
          }
        />
        <Route
          path="/single-hotel"
          element={
            <React.Suspense fallback={<PageLoader />}>
              <LazyAHotel />
            </React.Suspense>
          }
        />
        <Route path="*" element={<NoMatchPage />} />
        {/* manager links */}
        <Route
          path="/manager"
          element={
            <React.Suspense fallback={<PageLoader />}>
              <LazyManager />
            </React.Suspense>
          }
        >
          <Route
            index
            element={
              <React.Suspense fallback={<PageLoader />}>
                <LazyManagerHotels />
              </React.Suspense>
            }
          />
          <Route
            path="hotels"
            element={
              <React.Suspense fallback={<PageLoader />}>
                <LazyManagerHotels />
              </React.Suspense>
            }
          />
          {/* <Route
            path="messages"
            element={
              <React.Suspense fallback={<PageLoader />}>
                <LazyManageMessages />
              </React.Suspense>
            }
          /> */}
          <Route
            path="security"
            element={
              <React.Suspense fallback={<PageLoader />}>
                <LazyManagerSecurity />
              </React.Suspense>
            }
          />
        </Route>

        {/* admin links */}
        <Route
          path="/admin"
          element={
            <React.Suspense fallback={<PageLoader />}>
              <LazyAdmin />
            </React.Suspense>
          }
        >
          <Route
            index
            element={
              <React.Suspense fallback={<PageLoader />}>
                <LazyRecords />
              </React.Suspense>
            }
          />
          <Route
            path="records"
            element={
              <React.Suspense fallback={<PageLoader />}>
                <LazyRecords />
              </React.Suspense>
            }
          />
          <Route
            path="security"
            element={
              <React.Suspense fallback={<PageLoader />}>
                <LazyAdminSecurity />
              </React.Suspense>
            }
          />
          {/* <Route
            path="messages"
            element={
              <React.Suspense fallback={<PageLoader />}>
                <LazyAdminMessages />
              </React.Suspense>
            }
          /> */}
          <Route
            path="management"
            element={
              <React.Suspense fallback={<PageLoader />}>
                <LazyManageAdmin />
              </React.Suspense>
            }
          />
          <Route
            path="settings"
            element={
              <React.Suspense fallback={<PageLoader />}>
                <LazySettings />
              </React.Suspense>
            }
          />
        </Route>

        {/* owner links */}
        <Route
          path="/account"
          element={
            <React.Suspense fallback={<PageLoader />}>
              <LazyAccount />
            </React.Suspense>
          }
        >
          <Route
            index
            element={
              <React.Suspense fallback={<PageLoader />}>
                <LazyHotelList />
              </React.Suspense>
            }
          />
          <Route
            path="hotel-list"
            element={
              <React.Suspense fallback={<PageLoader />}>
                <LazyHotelList />
              </React.Suspense>
            }
          />
          <Route
            path="hotel-list/:singleHotel"
            element={
              <React.Suspense fallback={<PageLoader />}>
                <LazySingleHotel />
              </React.Suspense>
            }
          />
        </Route>

        {/* staff links */}
        <Route
          path="/staff"
          element={
            <React.Suspense fallback={<PageLoader />}>
              <LazyStaff />
            </React.Suspense>
          }
        >
          <Route
            index
            element={
              <React.Suspense fallback={<PageLoader />}>
                <LazyStaffHotel />
              </React.Suspense>
            }
          />
          <Route
            path="home"
            element={
              <React.Suspense fallback={<PageLoader />}>
                <LazyStaffHotel />
              </React.Suspense>
            }
          />
        </Route>
      </Routes>
      <ToastContainer theme="colored" bodyClassName="toast-body" />
    </>
  );
}

export default App;
