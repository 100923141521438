import { Grey1 } from "appConstants";
import "styles/typography.css";

export const P = ({ children, sm, md, color, style, className, onClick }) => (
  <div
    onClick={onClick}
    className={`P ${className}`}
    style={{ color, fontSize: md ? "14px" : sm ? "12px" : "", ...style }}
  >
    {children}
  </div>
);

export const Red = ({ children, sm, style, className, onClick }) => (
  <div
    onClick={onClick}
    className={`P ${className}`}
    style={{
      fontWeight: "600",
      color: "rgb(255,0,0)",
      fontSize: sm ? "12px" : "",
      ...style,
    }}
  >
    {children}
  </div>
);

export const Title0 = ({ color, children, style, className, onClick }) => (
  <div
    onClick={onClick}
    className={`Title0 ${className}`}
    style={{ color, fontWeight: "800", ...style }}
  >
    {children}
  </div>
);

export const Title1 = ({ color, children, style, className, onClick }) => (
  <div
    onClick={onClick}
    className={`Title1 ${className}`}
    style={{ color, fontWeight: "700", ...style }}
  >
    {children}
  </div>
);

export const Grey = ({ children, sm, style, className, onClick }) => (
  <div
    onClick={onClick}
    className={`P ${className}`}
    style={{ color: Grey1, fontSize: sm ? "12px" : "", ...style }}
  >
    {children}
  </div>
);

export const Title2 = ({
  thick,
  color,
  children,
  style,
  className,
  onClick,
}) => (
  <h4
    onClick={onClick}
    className={`Title2 ${className}`}
    style={{
      color,
      fontWeight: thick ? "700" : "",
      ...style,
    }}
  >
    {children}
  </h4>
);

export const Span = ({ children, sm, color, style, className, onClick }) => (
  <span
    onClick={onClick}
    className={`P ${className}`}
    style={{
      display: "inline-block",
      marginRight: "5px",
      color,
      fontSize: sm ? "12px" : "",
      ...style,
    }}
  >
    {children}
  </span>
);

export const Title3 = ({
  color,
  thick,
  children,
  style,
  className,
  onClick,
}) => (
  <h4
    onClick={onClick}
    className={`Title3 ${className}`}
    style={{
      fontWeight: thick ? "700" : "",
      color,
      ...style,
    }}
  >
    {children}
  </h4>
);
