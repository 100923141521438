import { Button, Grid, Hidden, IconButton, Paper, Rating } from "@mui/material";
import { Carousel } from "antd";
import { appColor } from "appConstants";
import { Grey1 } from "appConstants";
import { test3 } from "appConstants";
import { test5 } from "appConstants";
import { prodOne } from "appConstants";
import { governor } from "appConstants";
import { hott } from "appConstants";
import { test4 } from "appConstants";
import { test2 } from "appConstants";
import { test1 } from "appConstants";
import { OneIcon } from "appConstants";
import { TwoIcon } from "appConstants";
import { prodTwo } from "appConstants";
import { prodFour } from "appConstants";
import { prodThree } from "appConstants";
import { bannerSvg, State } from "appConstants";
import BannerCard from "components/bannerCard";
import GItem from "components/grids";
import AppLayout from "components/layouts/appLayout";
import SiteBtn from "components/siteBtn";
import SiteStepper from "components/siteStepper";
import { Title3 } from "components/typography";
import { P } from "components/typography";
import { Title1 } from "components/typography";
import { Title0 } from "components/typography";
import { Title2 } from "components/typography";
import React from "react";
import { BsHandThumbsUp } from "react-icons/bs";

import {
  MdEvent,
  MdFastForward,
  MdHotel,
  MdHotelClass,
  MdMonetizationOn,
  MdOutlineCloudUpload,
  MdOutlineLaunch,
  MdPersonAdd,
  MdUpload,
} from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { ad2 } from "appConstants";
import { ad4 } from "appConstants";
import { ad3 } from "appConstants";
import { ad1 } from "appConstants";
import { govText } from "appConstants";
import { govName } from "appConstants";

export const settings = {
  dots: false,
  autoplay: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
};

function Index() {
  const nav = useNavigate();
  return (
    <AppLayout>
      <Paper
        className="df ac"
        sx={{
          padding: "150px 4vw 100px",
          color: "white",

          height: "auto",
          bgcolor: appColor,
        }}
      >
        <Grid container className="jsb ac">
          <GItem sizes={[12, 8, 6, 4]}>
            <Title0 style={{ fontSize: "35px", fontWeight: "bolder" }}>
              Reservation and booking system for Hotels
            </Title0>
            <br />
            <P>Take your hotel business online with our help</P>
            <br />
            <SiteBtn
              bg="transparent"
              onClick={() => nav("/register")}
              font="18px"
              bdc="white"
              bw="1px"
              p={15}
            >
              Get Started
            </SiteBtn>
          </GItem>
          <Hidden smDown>
            <GItem sizes={[12, 8, 6, 4]}>
              <img src={bannerSvg} style={{ width: "100%", height: "auto" }} />
            </GItem>
          </Hidden>
        </Grid>
      </Paper>
      <Grid
        container
        className="jc"
        spacing={5}
        sx={{ padding: "0px 4vw", mt: -10 }}
      >
        <BannerCard
          color="red"
          icon={
            <MdOutlineLaunch style={{ color: "white", fontSize: "30px" }} />
          }
          title="Launch Hotel Booking Platform"
          content={
            <div style={{ textAlign: "justify" }}>
              The Launch Hotel Booking Platform is a cutting-edge solution that
              redefines the way travelers search for and book their
              accommodations. <br />
              <br />
              Designed with user-friendliness and convenience in mind, our
              platform provides an extensive collection of hotels, resorts, and
              lodges across various destinations globally.
              <br />
            </div>
          }
        />

        <BannerCard
          color={appColor}
          icon={<MdHotel style={{ color: "white", fontSize: "30px" }} />}
          title="Temporary Residence Booking"
          content={
            <div style={{ textAlign: "justify" }}>
              Discover a World of Comfort and Convenience Explore the Perfect
              Temporary Residence for Your Stay
              <br />
              <br />
              Welcome to a Place That Feels Like Home Browse Our Collection of
              Temporary Stays and Book Your Ideal Retreat Today
              <br />
              <br />
              Enjoy Peace of Mind and Unmatched Comfort with Our Premium
              Accommodations and Services
            </div>
          }
        />

        <BannerCard
          color="brown"
          icon={<MdEvent style={{ color: "white", fontSize: "30px" }} />}
          title={`${State} state events`}
          content={
            <div style={{ textAlign: "justify" }}>
              Embark on an Unforgettable Journey Experience the Vibrant Pulse of{" "}
              {State} state
              <br />
              <br />
              Discover the Hidden Gems of {State} state Unearth the Treasures
              That Make This Destination Unique
              <br />
              <br />
              Capture Every Moment and Create Lasting Memories Let the Spirit of{" "}
              {State} state Enrich Your Travel Story
            </div>
          }
        />
      </Grid>
      <Grid container className="jc">
        <GItem sizes={[12, 12, 12, 12]}>
          <Paper
            elevation={0}
            sx={{
              padding: "0px 4vw",
              py: 10,
              mb: 7,
              mt: 15,
              background: "rgb(255,255,245)",
            }}
          >
            <Grid
              container
              alignItems={"center"}
              justifyContent={"space-evenly"}
            >
              <GItem
                sizes={[12, 8, 8, 8]}
                style={{
                  lineHeight: "30px",
                  margin: "20px 0px 30px",
                  textAlign: "center",
                }}
              >
                <Title1>Upload Residence</Title1>
                <br />
                <P>
                  Seamless Accommodation Management Made Easy Effortlessly
                  Upload and Showcase Your Temporary Residences Open the Doors
                  to Countless Travelers Seeking Comfortable Stays Share Your
                  Unique Spaces with Wanderers Around the Globe Let Your
                  Properties Shine and Welcome Guests with Open Arms
                </P>
              </GItem>
              <GItem sizes={[8, 8, 6, 5]}>
                <img src={TwoIcon} style={{ width: "100%", height: "auto" }} />
              </GItem>
              <GItem sizes={[12, 6, 5, 5]}>
                <SiteStepper
                  color="brown"
                  steps={[
                    {
                      icon: (
                        <MdPersonAdd
                          style={{ color: "brown", fontSize: "30px" }}
                        />
                      ),

                      label: "Create Account",
                      description: `Create a residence owner account and login to get started`,
                    },
                    {
                      icon: (
                        <MdOutlineCloudUpload
                          style={{ color: "brown", fontSize: "30px" }}
                        />
                      ),
                      label: "Upload a residence",
                      description:
                        "Upload your temporary residence and make it available for booking",
                    },
                    {
                      icon: (
                        <MdMonetizationOn
                          style={{ color: "brown", fontSize: "30px" }}
                        />
                      ),
                      label: "Make Money!!!",
                      description: `Make money as your residence gets booked often.`,
                    },
                  ]}
                />
              </GItem>
            </Grid>
          </Paper>
        </GItem>
        <GItem sizes={[12, 12, 12, 9]} style={{ padding: "0px 4vw" }}>
          <Title2 thick style={{ textAlign: "center" }}>
            Ads
          </Title2>
          <div
            className="google-ads-section"
            style={{ padding: "10px", border: "2px solid black" }}
          >
            <div className="google-ads-header">
              <h2>Discover Products You'll Love</h2>
              <p>
                Explore a wide range of top-quality products available at
                unbeatable prices.
              </p>
            </div>

            <div className="google-ads-content">
              <Slider {...settings}>
                <div className="ads-card">
                  <img
                    style={{
                      objectFit: "cover",
                      width: "100%",
                      height: "200px",
                    }}
                    src={prodOne}
                    alt="Product 1"
                  />
                </div>
                <div className="ads-card">
                  <img
                    style={{
                      objectFit: "cover",
                      width: "100%",
                      height: "200px",
                    }}
                    src={prodTwo}
                    alt="Product 1"
                  />
                </div>
                <div className="ads-card">
                  <img
                    style={{
                      objectFit: "cover",
                      width: "100%",
                      height: "200px",
                    }}
                    src={prodThree}
                    alt="Product 1"
                  />
                </div>
                <div className="ads-card">
                  <img
                    style={{
                      objectFit: "cover",
                      width: "100%",
                      height: "200px",
                    }}
                    src={prodFour}
                    alt="Product 1"
                  />
                </div>
                <div className="ads-card">
                  <img
                    style={{
                      objectFit: "cover",
                      width: "100%",
                      height: "200px",
                    }}
                    src={prodOne}
                    alt="Product 1"
                  />
                </div>
                <div className="ads-card">
                  <img
                    style={{
                      objectFit: "cover",
                      width: "100%",
                      height: "200px",
                    }}
                    src={prodTwo}
                    alt="Product 1"
                  />
                </div>
                <div className="ads-card">
                  <img
                    style={{
                      objectFit: "cover",
                      width: "100%",
                      height: "200px",
                    }}
                    src={prodThree}
                    alt="Product 1"
                  />
                </div>
              </Slider>
            </div>
          </div>
        </GItem>
        <GItem sizes={[12, 12, 12, 12]}>
          <Paper
            elevation={0}
            sx={{
              padding: "0px 4vw",
              py: 10,
            }}
          >
            <Grid
              container
              alignItems={"center"}
              justifyContent={"space-evenly"}
            >
              <GItem
                sizes={[12, 8, 8, 8]}
                style={{
                  lineHeight: "30px",
                  margin: "20px 0px 30px",
                  textAlign: "center",
                }}
              >
                <Title1>Upload Hotels</Title1>
                <br />
                <P>
                  Seamless Accommodation Management Made Easy Effortlessly
                  Upload and Showcase Your Temporary Residences Open the Doors
                  to Countless Travelers Seeking Comfortable Stays Share Your
                  Unique Spaces with Wanderers Around the Globe Let Your
                  Properties Shine and Welcome Guests with Open Arms
                </P>
              </GItem>
              <GItem sizes={[12, 6, 5, 5]} className="df ac jfe">
                <SiteStepper
                  color="red"
                  steps={[
                    {
                      icon: (
                        <MdPersonAdd
                          style={{ color: "red", fontSize: "30px" }}
                        />
                      ),

                      label: "Create Account",
                      description: `Create a hotel owner account and login to get started`,
                    },
                    {
                      icon: (
                        <MdOutlineCloudUpload
                          style={{ color: "red", fontSize: "30px" }}
                        />
                      ),
                      label: "Upload a hotel",
                      description:
                        "Upload your hotel and hotel rooms and make it available for booking",
                    },
                    {
                      icon: (
                        <MdMonetizationOn
                          style={{ color: "red", fontSize: "30px" }}
                        />
                      ),
                      label: "Make Money!!!",
                      description: `Make money as your hotel rooms gets booked often.`,
                    },
                  ]}
                />
              </GItem>
              <Hidden smDown>
                <GItem sizes={[12, 8, 6, 6]}>
                  <img
                    src={OneIcon}
                    style={{ width: "100%", height: "auto" }}
                  />
                </GItem>
              </Hidden>
            </Grid>
          </Paper>
        </GItem>
        <GItem
          style={{ position: "relative", textAlign: "center" }}
          sizes={[12, 12, 12, 12]}
        >
          <div
            style={{
              background: "rgba(0,0,0,0.4)",
              position: "absolute",
              padding: "10px 20px",
              borderRadius: "15px",
              bottom: "10%",
              left: "10%",
            }}
          >
            <Title1 style={{ fontSize: "40px", color: "white" }} thick>
              {" "}
              {govName}
            </Title1>
            <Title3 style={{ color: "white" }}>{govText}</Title3>
          </div>
          <img src={governor} style={{ width: "100%", height: "auto" }} />
        </GItem>
        {/* <GItem
          sizes={[12, 12, 12, 9]}
          style={{ margin: "50px 0px", padding: "0px 4vw" }}
        >
          <Title2 thick style={{ textAlign: "center" }}>
            Ads
          </Title2>
          <div
            className="google-ads-section"
            style={{ padding: "10px", border: "2px solid black" }}
          >
            <div className="google-ads-header"></div>

            <div className="google-ads-content">
              <Slider {...settings}>
                <div className="ads-card">
                  <img
                    style={{
                      objectFit: "cover",
                      width: "100%",
                      height: "200px",
                    }}
                    src={ad3}
                    alt="Product 1"
                  />
                </div>
                <div className="ads-card">
                  <img
                    style={{
                      objectFit: "cover",
                      width: "100%",
                      height: "200px",
                    }}
                    src={ad1}
                    alt="Product 1"
                  />
                </div>
                <div className="ads-card">
                  <img
                    style={{
                      objectFit: "cover",
                      width: "100%",
                      height: "200px",
                    }}
                    src={ad2}
                    alt="Product 1"
                  />
                </div>
                <div className="ads-card">
                  <img
                    style={{
                      objectFit: "cover",
                      width: "100%",
                      height: "200px",
                    }}
                    src={ad3}
                    alt="Product 1"
                  />
                </div>
                <div className="ads-card">
                  <img
                    style={{
                      objectFit: "cover",
                      width: "100%",
                      height: "200px",
                    }}
                    src={ad4}
                    alt="Product 1"
                  />
                </div>
              </Slider>
            </div>
          </div>
        </GItem> */}
        <GItem sizes={[12, 12, 12, 12]}>
          <Paper
            elevation={0}
            sx={{
              padding: "0px 4vw",
              py: 10,
              mb: 7,
              background: "rgb(250,250,250)",
            }}
          >
            <Grid container spacing={4} justifyContent={"space-evenly"}>
              <GItem
                sizes={[12, 8, 8, 9]}
                style={{
                  lineHeight: "30px",
                  margin: "20px 0px 30px",
                  textAlign: "center",
                }}
              >
                <Title1>Testimonials</Title1>
                <br />
                <P>
                  Discover what our satisfied customers have to say about their
                  exceptional experiences with us. From seamless bookings to
                  unforgettable stays, our guests' testimonials reflect the
                  commitment we have to provide the best hospitality services.
                  Read through their experiences to get a glimpse of what awaits
                  you at our handpicked selection of hotels and accommodations.
                </P>
              </GItem>
              <BannerCard
                img={test1}
                title={
                  <div className="df ac jsb">
                    <span>Mr Chisom</span>
                    <Rating
                      icon={<BsHandThumbsUp />}
                      value={5}
                      sx={{ color: "brown" }}
                      readOnly
                    />
                  </div>
                }
                content={
                  <div>
                    My stay at this hotel was nothing short of magical. From the
                    moment I stepped in, I was greeted with warm smiles and an
                    incredibly hospitable staff. <br />
                    <br />
                    The room's elegant decor and the breathtaking view from the
                    balcony added to the charm.
                    <br />
                    <br /> The in-house dining options were delightful, offering
                    a blend of local and international cuisines. I found the
                    entire experience to be an exquisite fusion of luxury and
                    comfort.
                  </div>
                }
              />

              <BannerCard
                img={test2}
                title={
                  <div className="df ac jsb">
                    <span>Mr Odinaka</span>
                    <Rating
                      icon={<BsHandThumbsUp />}
                      value={4}
                      sx={{ color: "brown" }}
                      readOnly
                    />
                  </div>
                }
                content={
                  <div>
                    I was thoroughly impressed with the quality of service and
                    the attention to detail at this hotel. Every need was
                    anticipated and catered to, making my stay an absolute
                    pleasure. <br />
                    <br />
                    The well-appointed rooms, coupled with the serene ambiance
                    of the hotel, provided the perfect setting for a relaxing
                    getaway. The central location also made it convenient to
                    explore the city's attractions.
                    <br />
                    <br /> I can't wait to return and relive the exceptional
                    hospitality."
                  </div>
                }
              />

              <BannerCard
                img={test3}
                title={
                  <div className="df ac jsb">
                    <span>Mister Uche</span>
                    <Rating
                      icon={<BsHandThumbsUp />}
                      value={4}
                      sx={{ color: "brown" }}
                      readOnly
                    />
                  </div>
                }
                content={
                  <div>
                    This hotel is truly a hidden gem. Tucked away from the
                    bustle of the city, it offers a peaceful retreat that is
                    second to none.
                    <br /> <br />
                    The lush surroundings and well-maintained amenities created
                    a tranquil environment, ideal for unwinding and
                    rejuvenating. <br />
                    <br />
                    The thoughtful gestures by the staff added a personal touch
                    to my stay, making me feel right at home. I can't wait to
                    escape to this serene haven again.
                  </div>
                }
              />
              {/* <BannerCard
                img={test4}
                title={
                  <div className="df ac jsb">
                    <span>Miss Chidimma</span>
                    <Rating
                      icon={<BsHandThumbsUp />}
                      value={5}
                      sx={{ color: "brown" }}
                      readOnly
                    />
                  </div>
                }
                content={
                  <div>
                    I had a wonderful experience at this hotel, thanks to the
                    attentive and accommodating staff. Every aspect of my stay,
                    from check-in to check-out, was seamless and hassle-free.
                    <br />
                    <br />
                    The well-furnished rooms were equipped with all the
                    necessary amenities, ensuring a comfortable and convenient
                    stay. The on-site dining options served delectable dishes
                    that tantalized my taste buds.
                    <br />
                    <br /> I would recommend this service to anyone looking for
                    a blend of comfort and convenience.
                  </div>
                }
              />
              <BannerCard
                img={test3}
                title={
                  <div className="df ac jsb">
                    <span>Mister Uche</span>
                    <Rating
                      icon={<BsHandThumbsUp />}
                      value={5}
                      sx={{ color: "brown" }}
                      readOnly
                    />
                  </div>
                }
                content={
                  <div>
                    Earlier i gave a four star but i must confess this, here the
                    attention to detail, coupled with the commitment to guest
                    satisfaction, made my stay a remarkable one.
                    <br />
                    <br /> The thoughtfully designed rooms and the stunning
                    views from the windows offered a serene and picturesque
                    setting. The well-trained staff provided impeccable service,
                    making me feel valued and cared for throughout my stay.
                    <br />
                    <br /> This hotel is, without a doubt, my top choice for any
                    future travels to the area.
                  </div>
                }
              />
              <BannerCard
                img={test5}
                title={
                  <div className="df ac jsb">
                    <span>Mr Franklin</span>
                    <Rating
                      icon={<BsHandThumbsUp />}
                      value={5}
                      sx={{ color: "brown" }}
                      readOnly
                    />
                  </div>
                }
                content={
                  <div>
                    I can't praise this hotel enough for the exceptional
                    hospitality and the unparalleled comfort it provided. The
                    meticulously designed interiors and the well-curated
                    facilities made my vacation truly memorable.
                    <br />
                    <br /> The courteous staff added a personal touch to the
                    service, ensuring that every request was promptly attended
                    to.
                    <br />
                    <br /> The variety of recreational activities offered on the
                    premises made my stay all the more enjoyable.
                  </div>
                }
              /> */}
            </Grid>
          </Paper>
        </GItem>
      </Grid>
    </AppLayout>
  );
}

export default Index;
