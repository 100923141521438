import {
  AppBar,
  Box,
  Container,
  Divider,
  Hidden,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "styles/layout.css";
// import AuthMenuItem from "./configs/authMenuItem";
// import { FiUsers, FiHome } from "react-icons/fi"
import SiteBtn from "components/siteBtn";
import Brand from "components/brand";
import { useDashboardPath } from "hooks/useDashboardLinks";
import { MdPersonOutline } from "react-icons/md";
import { appColor } from "appConstants";
import { HiOutlineViewList } from "react-icons/hi";
import { Drawer } from "antd";
// import MobileDrawer from "./configs/mobileDrawer";

export default function Navbar() {
  const navigate = useNavigate();
  const path = useDashboardPath();

  const [isModalVisible, setIsModalVisible] = React.useState("");

  const showModal = () => {
    setIsModalVisible(!isModalVisible);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Box style={{ backgroundColor: "white" }} sx={{ flexGrow: 1 }}>
        <AppBar position="fixed" color="inherit" elevation={0}>
          <Container disableGutters={true} maxWidth="xl">
            <Toolbar
              style={{
                boxShadow: "0px 0.5px 3px rgb(210,210,210)",
                padding: "10px 4vw 10px 4vw",
                minHeight: "80px",
              }}
            >
              <Link to="/">
                <Brand />
              </Link>
              <Box sx={{ flexGrow: 1 }} />
              <Hidden smUp>
                <Box
                  className="df ac"
                  sx={{ fontWeight: "700", width: "auto", mt: 1 }}
                >
                  <HiOutlineViewList
                    onClick={showModal}
                    color={appColor}
                    size={"30px"}
                  />
                </Box>
              </Hidden>
              <Hidden smDown>
                <Box
                  className="df ac"
                  sx={{ fontWeight: "700", width: "auto", mt: 1 }}
                >
                  <Box
                    sx={{ px: 2, cursor: "pointer" }}
                    onClick={() => navigate("/hotels")}
                  >
                    Hotels
                  </Box>
                  <Box
                    sx={{ px: 2, cursor: "pointer" }}
                    onClick={() => navigate(path)}
                  >
                    Dashboard
                  </Box>
                </Box>
              </Hidden>
              <Box className="df">
                {/* here for school select */}
                <Hidden smDown>
                  <SiteBtn p={7} onClick={() => navigate("/login")}>
                    Login | Register
                  </SiteBtn>
                </Hidden>
                {/* <MobileDrawer /> */}
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
      </Box>
      <Drawer
        drawerStyle={{ padding: "0px", paddingTop: "30px" }}
        title="Menu List"
        width={250}
        closable={true}
        zIndex={1000}
        placement="right"
        onClose={() => handleCancel()}
        visible={isModalVisible}
      >
        <Box
          sx={{ py: 1, cursor: "pointer" }}
          onClick={() => navigate("/hotels")}
        >
          Hotels
        </Box>
        <Divider sx={{ my: 1 }} />
        <Box sx={{ py: 1, cursor: "pointer" }} onClick={() => navigate(path)}>
          Dashboard
        </Box>
        <Divider sx={{ my: 1 }} />
        <Box
          sx={{ py: 1, cursor: "pointer" }}
          onClick={() => navigate("/login")}
        >
          {/* here for school select */}
          Login | Register
        </Box>
      </Drawer>
    </>
  );
}
