import { createAsyncThunk } from "@reduxjs/toolkit";
import { deleteAsync } from "http/method";
import { patchAsync } from "http/method";
import { postAsync } from "http/method";
import { getAsync } from "http/method";

export const getHotels = createAsyncThunk("get/hotels", async (data) => {
  return await getAsync({ url: "hotels", data });
});

export const createHotel = createAsyncThunk("create/hotel", async (data) => {
  await postAsync({ url: "hotels/upload", payload: data });
});

export const verifyPayment = createAsyncThunk(
  "reservations/payments/verify",
  async (data) => {
    await postAsync({ url: "reservations/payments/verify", payload: data });
  }
);

export const getSingleHotel = createAsyncThunk(
  "get/singleHotel",
  async (data) => {
    return await getAsync({ url: `hotels/${data?.slug}`, data });
  }
);

export const getUserSingleHotel = createAsyncThunk(
  "get/userSingleHotel",
  async (data) => {
    return await getAsync({ url: `hotels/${data?.slug}`, data });
  }
);
export const updateHotelStatus = createAsyncThunk(
  "update/hotelStatus",
  async (data) => {
    await patchAsync({ url: `hotels/${data?.slug}/status`, payload: data });
  }
);

export const deleteHotel = createAsyncThunk("delete/hotel", async (data) => {
  await deleteAsync({ url: `hotels/${data?.slug}`, payload: data });
});

export const getHotelTaskRecord = createAsyncThunk(
  "get/hotelTaskRecord",
  async (data) => {
    return await getAsync({ url: `tax_records/hotels/${data?.slug}`, data });
  }
);

export const getHotelStaffs = createAsyncThunk(
  "get/hotelStaff",
  async (data) => {
    return await getAsync({ url: `hotels/employees/${data?.slug}`, data });
  }
);

//hotels/employees/{slug}
