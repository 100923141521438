import React from "react";
import { Title3, P } from "./typography";
import GItem from "./grids";
import { IconButton, Paper } from "@mui/material";
import Dots from "./dots";
import { appColor } from "appConstants";

function BannerCard({ title, img, content, icon, color }) {
  return (
    <GItem sizes={[12, 8, 6, 4]}>
      <Paper
        variant="none"
        sx={{
          minHeight: "450px",
          position: "relative",
          px: 3,
          py: 4,
          border: "1px solid rgb(200,255,200)",
          background: "rgb(255,255,240)",
          borderRadius: "10px",
        }}
      >
        {img ? (
          <div style={{ textAlign: "center" }}>
            <img
              src={img}
              style={{
                borderRadius: "50%",
                border: `2px solid ${appColor}`,
                width: "150px",
                height: "150px",
                objectFit: "cover",
              }}
            />
          </div>
        ) : (
          <IconButton sx={{ background: color, p: 2 }}>{icon}</IconButton>
        )}

        <br />
        <br />
        <Title3 thick={true}>{title}</Title3>

        <P md style={{ textAlign: "justify" }}>
          {content}
        </P>
        <div style={{ position: "absolute", bottom: "10px", right: "10px" }}>
          <Dots color={color ?? "brown"} />
        </div>
      </Paper>
    </GItem>
  );
}

export default BannerCard;
